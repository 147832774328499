import React, { useEffect } from "react";
import { useParams,useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import {
    loginEmailcheck
  } from "../../components/api/accountAction";
  import { useDispatch } from "react-redux";

  import {
    cognitoSignIn
  } from "../Amplify/auth-config";

const AuthTokenOptix = () => {

    // const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useParams();
    console.log(token,"TOKENNNN")
    useEffect(() => {
        const verifyUser = async () => {
            try {
                if (!token) {
                    console.error("No token found in URL parameter.");
                    return;
                }
    
                const [header, payload] = token.split('.');
                const decodedPayload = JSON.parse(atob(payload));
                console.log("Decoded Payload:", decodedPayload);
    
                if (!decodedPayload?.username || !decodedPayload?.password) {
                    console.error("Username or password is missing in the decoded token.");
                    return;
                }
    
                const { username, password } = decodedPayload;
                console.log("Username:", username);
                var new_email = username;
                const input = {
                    user_id: username,
                    password: password,
                };
    
                try {
                    const signInData = await dispatch(cognitoSignIn(input)).unwrap();
    
                    localStorage.setItem(
                        "_token",
                        signInData.signInUserSession.accessToken.jwtToken
                    );
    
                    try {
                        const { data } = await dispatch(loginEmailcheck(input)).unwrap();
    
                        const emailVerification = JSON.parse(data.email_verification)[0];
                        const encodedString = btoa(emailVerification.user_name);
                        const encodedEmail = btoa(new_email);
                        const normalEncodedString = btoa("normal");
                        const storeUserDetails = btoa(
                            emailVerification.first_name || new_email
                        );
    
                        localStorage.setItem(
                            "TokenOptixIdentityServiceProvider.usr",
                            encodedString
                        );
                        localStorage.setItem(
                            "TokenOptixIdentityServiceProvider.logtype",
                            normalEncodedString
                        );
                        localStorage.setItem(
                            "TokenOptixIdentityServiceProvider.store",
                            storeUserDetails
                        );
                        localStorage.setItem(
                            "TokenOptixIdentityServiceProvider.auth",
                            encodedEmail
                        );
    
                        window.location.href = "/apphub";
                    } catch (err) {
                        console.error("Error during email verification:", err);
                    }
                } catch (err) {
                    console.error("Error during sign-in:", err);
                }
            } catch (err) {
                console.error("Error verifying user:", err);
            }
        };
    
        verifyUser();
    }, [token]);
    


    return (
        <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Spin tip="Loading..." />
    </div>
    );
};

export default AuthTokenOptix;
